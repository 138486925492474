import { all } from 'redux-saga/effects';

import { default as getSystemImageSaga } from '../../features/systemFiles/getSystemFiles/sagas';
import { default as updateSystemImageSaga } from '../../features/systemFiles/deleteSystemFiles/sagas';
import { default as deleteSystemImageSaga } from '../../features/systemFiles/updateSystemFiles/sagas';

export default function* systemImageSaga() {
  yield all([
    getSystemImageSaga(),
    updateSystemImageSaga(),
    deleteSystemImageSaga(),
  ]);
}
