import React from 'react';
import Button from '../Button';
import CloseIcon from '@mui/icons-material/Close';
const CloseButtonIcon = ({ onClick }) => {
  return (
    <Button
      variant={'contained'}
      color={'primary'}
      size={'small'}
      className={'icon light'}
      onClick={onClick}
    >
      <CloseIcon />
    </Button>
  );
};

export default CloseButtonIcon;
