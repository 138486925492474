import { neutralColors, systemColors, themeColors } from '../colors';

const buttonStyles = {
  MuiButton: {
    root: {
      borderRadius: '16px',
      boxShadow: 'none',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: '700',
      textTransform: 'none',
      transition: 'none',
      maxWidth: 'max-content',
      '&.icon': {
        width: '2rem',
        height: '2rem',
        padding: '0.25rem !important',
      },
      '&.light': {
        backgroundColor: 'transparent',
        color: neutralColors.neutral900,
        '&:hover': {
          backgroundColor: themeColors.secondaryLighter,
        },
        '&:focus': {
          border: `2px solid ${systemColors.infoLight}`,
          padding: '6px',
        },
        '&:active': {
          backgroundColor: themeColors.secondaryRegular,
          border: 'none',
          color: neutralColors.neutralWhite,
        },
        '&.Mui-disabled': {
          backgroundColor: neutralColors.neutral200,
          color: neutralColors.neutral400,
        },
      },
    },
    label: {
      gap: '1rem',
    },
    contained: {
      boxShadow: 'none',
      '&.size-tiny': {
        borderRadius: '8px',
        padding: '4px 8px',
        '&.icon': {
          minWidth: 0,
          padding: '4px',
        },
      },
    },
    containedPrimary: {
      backgroundColor: themeColors.primaryRegular,
      color: themeColors.primaryContrastText,
      '&:hover': {
        backgroundColor: themeColors.primaryDark,
        boxShadow: 'none',
      },
      '&:active': {
        backgroundColor: 'transparent',
        color: themeColors.primaryRegular,
        border: `2px solid ${themeColors.primaryRegular}`,
        boxShadow: 'none',
      },
      '&.Mui-disabled': {
        backgroundColor: neutralColors.neutral200,
        color: neutralColors.neutral400,
      },
    },
    containedSizeLarge: {
      padding: '16px 24px',
      '&:active': {
        padding: '14px 22px',
      },
    },
    containedSizeSmall: {
      borderRadius: '8px',
      fontSize: 14,
      padding: '8px 12px',
      '&:active': {
        padding: '6px 10px',
      },
      '&.icon': {
        minWidth: 0,
        padding: '8px',
      },
    },
    outlinedPrimary: {
      backgroundColor: 'transparent',
      border: `2px solid ${themeColors.primaryRegular}`,
      color: themeColors.primaryRegular,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
        color: themeColors.primaryLight,
        border: `2px solid ${themeColors.primaryDark}`,
      },
      '&:focus': {
        backgroundColor: 'transparent',
        color: themeColors.primaryRegular,
        border: `2px solid ${systemColors.infoLight}`,
      },
      '&:active': {
        backgroundColor: themeColors.primaryDark,
        border: `2px solid ${themeColors.primaryDark}`,
        color: themeColors.primaryContrastText,
      },
      '&.Mui-disabled': {
        backgroundColor: neutralColors.neutralBg,
        border: `2px solid ${neutralColors.neutral300}`,
        color: neutralColors.neutral400,
      },
    },
    outlinedSecondary: {
      backgroundColor: 'transparent',
      border: `2px solid ${themeColors.secondaryRegular}`,
      color: themeColors.secondaryRegular,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
        color: systemColors.infoDark,
        border: `2px solid ${systemColors.infoDark}`,
      },
      '&:focus': {
        backgroundColor: 'transparent',
        color: themeColors.secondaryLight,
        border: `2px solid ${themeColors.secondaryLight}`,
      },
      '&:active': {
        backgroundColor: systemColors.infoDark,
        border: `2px solid ${systemColors.infoDark}`,
        color: themeColors.primaryContrastText,
      },
      '&.Mui-disabled': {
        backgroundColor: neutralColors.neutralBg,
        border: `2px solid ${neutralColors.neutral300}`,
        color: neutralColors.neutral400,
      },
    },
    outlinedSizeLarge: {
      padding: '1rem 1.5rem',
    },
    outlinedSizeSmall: {
      borderRadius: '0.5rem',
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '1.5rem',
      padding: '0.25rem 0.5rem',
    },
    textPrimary: {
      backgroundColor: 'transparent',
      color: themeColors.primaryRegular,
      border: `2px solid transparent`,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
        color: themeColors.primaryLight,
        border: `2px solid ${themeColors.primaryDark}`,
      },
      '&:focus': {
        backgroundColor: 'transparent',
        color: themeColors.primaryRegular,
        border: `2px solid ${systemColors.infoLight}`,
      },
      '&:active': {
        backgroundColor: themeColors.primaryDark,
        border: `2px solid ${themeColors.primaryDark}`,
        color: `${themeColors.primaryContrastText} !important`,
      },
      '&.Mui-disabled': {
        backgroundColor: neutralColors.neutralBg,
        border: `2px solid ${neutralColors.neutral300}`,
        color: neutralColors.neutral400,
      },
    },
    textSizeLarge: {
      width: '56px',
      height: '56px',
      maxWidth: '56px',
      maxHeight: '56px',
      minWidth: '56px',
      minHeight: '56px',
      padding: '8px',
      borderRadius: '16px',
    },
    textSizeSmall: {
      width: '40px',
      height: '40px',
      maxWidth: '40px',
      maxHeight: '40px',
      minWidth: '40px',
      minHeight: '40px',
      borderRadius: '8px',
      padding: '8px',
    },
  },
};

export default buttonStyles;
