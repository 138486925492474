import React, { useEffect, useState } from 'react';
import PlatformParameters from './components/PlatformParameters/PlatformParameters';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTeamGroupKeyStart } from '../../../../features/teamGroup/getTeamGroup/slices';
import { Loader } from '../../../../../components';
import { withStyles } from '@mui/styles';
import LFSSParameters from './components/LFSSParameters/LFSSParameters';
import { getContractDetailsKeyStart } from '../../../../features/contractDetails/getContractDetails/slices';
import { getRewardsKeyStart } from '../../../../features/rewards/getRewards/slices';
import { PointsParameters } from './components/PointsParameters';
import { ProductsParameters } from './components/ProductsParameters';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
};

const ParametersTabs = ({ ...props }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [refreshState, setRefreshState] = useState(false);

  useEffect(() => {
    dispatch(getTeamGroupKeyStart({ teamGroupId: params.contract }));
    dispatch(getContractDetailsKeyStart({ teamGroupId: params.contract }));
    dispatch(
      getRewardsKeyStart({
        teamGroupId: params.contract,
        distributor: 'Edenred',
      }),
    );
  }, [refreshState]);

  const teamGroup = useSelector(state => state.getTeamGroupKey);
  const contractDetails = useSelector(state => state.getContractDetailsKey);
  const rewards = useSelector(state => state.getRewardsKey);

  const refresh = () => {
    setRefreshState(!refreshState);
  };

  return (
    <div className={props.classes.root}>
      {teamGroup.loading && <Loader centered />}
      {teamGroup.success && <PlatformParameters refreshState={refresh} />}

      {contractDetails.loading && <Loader centered />}
      {contractDetails.success && <LFSSParameters />}

      {contractDetails.loading && <Loader centered />}
      {contractDetails.success && <PointsParameters />}

      {rewards.loading && <Loader centered />}
      {rewards.success && <ProductsParameters />}
    </div>
  );
};

export default withStyles(styles)(ParametersTabs);
