import React, { useEffect, useState } from 'react';
import createRewardAllocationsStore from '../../../../../../../../store/reward/store';
import { Provider } from 'react-redux';
import { RewardComponent } from './components/RewardComponent';

const Reward = ({ ...props }) => {
  const store = createRewardAllocationsStore();
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowComponent(true);
    }, 100);
  }, []);

  return (
    <Provider store={store}>
      {showComponent && <RewardComponent {...props} />}
    </Provider>
  );
};

export default Reward;
