import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Formsy from 'formsy-react';
import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  Switch,
  Typography,
} from '@material-ui/core';
import Button from '../../../../../../components/Button';
import useDirtyPristine from '../../../../../../hooks/UseDirtyPristine';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useHandleToast from '../../../../../../hooks/UseHandleToast';
import {
  updateContractDetailsKeyClear,
  updateContractDetailsKeyStart,
} from '../../../../../../features/contractDetails/updateContractDetails/slices';
import { useParams } from 'react-router-dom';
import TextField from '../../../../../../components/TextField/TextField';
import { Loader } from '../../../../../../../components';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  titleGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
  },
  pointsExpiration: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  pointsExpirationEnabled: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  conversionField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const PointsParameters = ({ ...props }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { contract } = useParams();
  const { dirtyChange, resetPristinePage } = useDirtyPristine();

  const getContractDetails = useSelector(state => state.getContractDetailsKey);
  const updateContractDetailsKey = useSelector(
    state => state.updateContractDetailsKey,
  );

  const [pointsExpirationEnabled, setPointsExpirationEnabled] = useState(
    getContractDetails.contractDetails
      ? getContractDetails.contractDetails.additional_points_validity_months !==
          null
      : false,
  );
  const [pointExpirationValue, setPointExpirationValue] = useState(
    getContractDetails.contractDetails.additional_points_validity_months
      ? getContractDetails.contractDetails.additional_points_validity_months
      : '',
  );
  const [pointConversionValue, setPointConversionValue] = useState(
    getContractDetails.contractDetails
      ? getContractDetails.contractDetails.point_value
      : '',
  );
  const [listener, setListener] = useState(false);

  const clearListener = () => {
    setListener(false);
  };

  useHandleToast(
    updateContractDetailsKey,
    dispatch,
    updateContractDetailsKeyClear,
    'spider.hierarchy_node.platform_customization.parameters.points.update.success',
    'spider.hierarchy_node.platform_customization.parameters.points.update.error',
    listener,
    clearListener,
  );

  const checkPointExpiration = event => {
    dirtyChange();
    setPointsExpirationEnabled(event.target.checked);
  };

  const conversionValueChange = e => {
    dirtyChange();
    setPointConversionValue(e.target.value);
  };

  const pointExpirationValueChange = e => {
    dirtyChange();
    setPointExpirationValue(e.target.value);
  };

  const savePlatformContractDetails = () => {
    setListener(true);
    resetPristinePage();
    dispatch(
      updateContractDetailsKeyStart({
        teamGroupId: contract,
        contractDetails: {
          additional_points_validity_months: pointsExpirationEnabled
            ? pointExpirationValue
            : null,
          point_value: pointConversionValue,
        },
      }),
    );
  };

  return updateContractDetailsKey.loading ? (
    <Loader centered />
  ) : (
    <Formsy
      name={'parameter-contract-details'}
      onValidSubmit={savePlatformContractDetails}
      className={props.classes.root}
    >
      <div className={props.classes.titleGroup}>
        <Typography variant={'h4'} component={'h4'}>
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.points.title',
          })}
        </Typography>
      </div>

      <Typography variant={'subtitle1'}>
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.points.subtitle',
        })}
      </Typography>

      <div className={props.classes.conversionField}>
        <Typography variant={'subtitle1'} style={{ fontWeight: 'bold' }}>
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.points.preInputLabel',
          })}
        </Typography>

        <TextField
          name={'conversionValue'}
          type={'number'}
          value={pointConversionValue}
          label={intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.points.conversionValueLabel',
          })}
          variant={'outlined'}
          required
          style={{ width: '53%' }}
          InputProps={{
            startAdornment: <InputAdornment position='start'>€</InputAdornment>,
          }}
          inputProps={{
            min: 0,
          }}
          validations={{
            isNumeric: true,
            isPositive: true,
          }}
          validationErrors={{
            isDefaultRequiredValue: intl.formatMessage({
              id: 'common.form.required_error',
            }),
            isNumeric: intl.formatMessage({
              id: 'common.form.numeric_error',
            }),
            isPositive: intl.formatMessage({
              id: 'common.form.is_more_than_or_equals_0_error',
            }),
          }}
          onChange={conversionValueChange}
        />
      </div>

      <div className={props.classes.pointsExpiration}>
        <FormControl style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            checked={pointsExpirationEnabled}
            control={<Switch color={'primary'} />}
            labelPlacement='start'
            label={intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.parameters.points.pointExpirationEnabledLabel',
            })}
            style={{ margin: 0 }}
            onChange={checkPointExpiration}
          />
        </FormControl>

        {pointsExpirationEnabled && (
          <div className={props.classes.pointsExpirationEnabled}>
            <div>
              <Typography variant={'body2'}>
                {intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.points.pointExpirationEnabledInfo',
                })}
              </Typography>
              <Typography variant={'body2'}>
                {intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.points.exampleLabel',
                })}
              </Typography>
              <Typography variant={'body2'}>
                {intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.points.exampleOneLabel',
                })}
              </Typography>
              <Typography variant={'body2'}>
                {intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.points.exampleTwoLabel',
                })}
              </Typography>
            </div>

            <TextField
              name={'pointExpiration'}
              type={'number'}
              value={pointExpirationValue}
              label={intl.formatMessage({
                id: 'spider.hierarchy_node.platform_customization.parameters.points.pointExpirationValueLabel',
              })}
              variant={'outlined'}
              required
              style={{ width: '60%' }}
              inputProps={{
                min: 0,
              }}
              validations={{
                isInt: true,
              }}
              validationErrors={{
                isDefaultRequiredValue: intl.formatMessage({
                  id: 'common.form.required_error',
                }),
                isInt: intl.formatMessage({
                  id: 'common.form.is_int_error',
                }),
              }}
              onChange={pointExpirationValueChange}
            />
          </div>
        )}
      </div>

      <div className={props.classes.actions}>
        <Button
          color={'primary'}
          variant={'outlined'}
          size={'small'}
          type={'submit'}
        >
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.saveButtonLabel',
          })}
        </Button>
      </div>
    </Formsy>
  );
};

export default withStyles(styles)(PointsParameters);
