import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { RowsPerPageSelector } from '../RowsPerPageSelector';
import UsePagination from '../../hooks/UsePagination';
import { Pagination, CircularProgress } from '@mui/material';
import { Participant } from './components/Participant';
import { PointsBalance } from './components/PointsBalance';
import HierarchyNodeUserStatus from '../../components/HierarchyNodeUserStatus';
import { systemColors } from '../../themes';
import EmptyTableImage from '../../assets/img/empty-table-participants.png';
import { EmptyTableView } from '../EmptyTableView';
import { Button } from '@src/Spider/components';
import { ArrowRight } from 'iconsax-react';
import { Link } from 'react-router-dom';
import { useLazyGetHierarchyNodeUsersListQuery } from '@src/data/api/endpoints/participants';
import { ParticipantUrl } from '@src/Spider/enums/participantUrl';

export const ParticipantsList = () => {
  const intl = useIntl();
  const { contract } = useParams();
  const [fetch, { data, isLoading, isFetching, error }] =
    useLazyGetHierarchyNodeUsersListQuery();
  const {
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationCount,
  } = UsePagination({
    dataLength: data?.count ? data?.count : 0,
  });

  useEffect(() => {
    fetch({
      id: contract,
      include_point_balance: true,
      include_manager: true,
      page: pagination.page,
      page_size: pagination.rowsPerPage,
    });
  }, [pagination.page, pagination.rowsPerPage]);

  const columns = [
    'matricule',
    'participant',
    'manager',
    'points',
    'status',
  ].map(el => ({
    field: el,
    label: intl.formatMessage({
      id: `spider.participants.tabs.list.table.${el}`,
    }),
  }));

  return (
    <>
      <Box display='flex' flexDirection='column'>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            {isFetching && <CircularProgress color='primary' size='1.2rem' />}
            {Boolean(error) && (
              <Typography style={{ color: systemColors.errorRegular }}>
                {error?.message}
              </Typography>
            )}
          </Box>
          <RowsPerPageSelector
            rowsPerPage={pagination.rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
        <TableContainer
          component={Paper}
          style={{ maxWidth: '100%', overflow: 'scroll', maxHeight: '50dvh' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {(isLoading || Boolean(data?.count)) &&
                  columns.map(column => {
                    return (
                      <TableCell
                        key={column.field}
                        style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  })}
                <TableCell
                  style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.count > 0 &&
                data.results.map(user => (
                  <TableRow key={user.uuid}>
                    <TableCell>
                      <Typography>{user.first_identifier}</Typography>
                    </TableCell>
                    <TableCell>
                      <Participant owner={user} />
                    </TableCell>
                    <TableCell>
                      {user?.manager ? (
                        <Typography style={{ whiteSpace: 'nowrap' }}>
                          {user.manager.firstname} {user.manager.lastname}
                        </Typography>
                      ) : (
                        '--'
                      )}
                    </TableCell>
                    <TableCell>
                      <PointsBalance point_balance={user.point_balance} />
                    </TableCell>
                    <TableCell>
                      <HierarchyNodeUserStatus status={user.status} />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        component={Link}
                        to={`/nodes/${contract}/users/${user.uuid}/${ParticipantUrl.DETAILS}`}
                      >
                        <ArrowRight />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              {data?.count === 0 && (
                <TableRow>
                  <TableCell>
                    <EmptyTableView
                      imageSrc={EmptyTableImage}
                      imageAlt='Aucun utilisateur'
                      title='spider.participants.tabs.list.table.no-data.title'
                      description='spider.participants.tabs.list.table.no-data.description'
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display='flex' width='100%' justifyContent='center' mt='1.5rem'>
          <Pagination
            count={paginationCount}
            onChange={handleChangePage}
            variant='outlined'
          />
        </Box>
      </Box>
    </>
  );
};
