import React from 'react';
import { DialogContent, Drawer, Paper, Typography } from '@material-ui/core';
import { DialogIndicator } from '@spider:src/components/DialogIndicator';
import { Button } from '@spider:src/components';
import { neutralColors } from '@spider:src/themes';
import { createTheme } from '@material-ui/core/styles';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { useMediaQuery } from '@mui/material';
import { CloseButtonIcon } from '@spider:src/components/CloseButtonIcon';

const styles = {
  dialogTitle: {
    display: 'flex !important',
    flexDirection: 'column !important',
    padding: '1rem !important',
    width: '100%',
  },
  closeTitleDialog: {
    display: 'flex !important',
    width: '100%',
    flexDirection: 'row-reverse !important',
    padding: '1rem !important',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '1rem',
    overflow: 'auto',
    backgroundColor: neutralColors.neutralWhite,
    [createTheme().breakpoints.up('md')]: {
      backgroundColor: neutralColors.neutralBg,
      padding: '0rem 2rem 2rem 2rem !important',
    },
  },
  dialogPaperContent: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '1rem',
    marginRight: 'auto',
    marginLeft: 'auto',
    '&.MuiPaper-root': {
      boxShadow: 'none',
    },
    gap: '1rem',
    [createTheme().breakpoints.up('md')]: {
      gap: '2rem',
      padding: '1rem',
      width: '75%',
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      borderTopLeftRadius: '0.75rem',
      borderTopRightRadius: '0.75rem',
      border: 'none',
      height: '90vh',
      [createTheme().breakpoints.up('sm')]: {
        height: '100vh',
        border: '4px solid',
        borderBottom: 'none',
        borderColor: neutralColors.neutral300,
      },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    minHeight: '48px',
    [createTheme().breakpoints.down('sm')]: {
      minHeight: '6px',
    },
  },
};

const RewardHelperAboutDialog = ({
  AboutComponent,
  title,
  open,
  setOpen,
  onConvert,
  disableConvert,
  ...props
}) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(createTheme().breakpoints.down('sm'));

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      anchor='bottom'
      open={open}
      onClose={onClose}
      className={props.classes.drawer}
    >
      <DialogContent className={props.classes.dialogContent}>
        <div className={props.classes.header} onClick={onClose}>
          <DialogIndicator />
        </div>
        {!isMobile && (
          <div className={props.classes.closeTitleDialog}>
            <CloseButtonIcon onClick={onClose} />
          </div>
        )}
        <div className={props.classes.dialogTitle}>
          <Typography
            variant={'h1'}
            component={'h1'}
            className={'title underline-center'}
          >
            {title}
          </Typography>
        </div>
        <Paper className={props.classes.dialogPaperContent}>
          <AboutComponent />
        </Paper>

        {!isMobile && (
          <div>
            <Button
              color={'primary'}
              variant={'contained'}
              size={'large'}
              onClick={onConvert}
              disabled={disableConvert}
            >
              {intl.formatMessage({
                id: 'spider.convertPoints.home.rewardsMain.convertAction',
              })}
            </Button>
          </div>
        )}
      </DialogContent>
    </Drawer>
  );
};

export default withStyles(styles)(RewardHelperAboutDialog);
