import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { Document as DocumentIcon } from 'iconsax-react';
import { useIntl } from 'react-intl';
import { Divider, Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import RegulationDocument from '../RegulationDocument/RegulationDocument';
import { Button } from '../../../../../../../../components';
import { Loader } from '../../../../../../../../../components';
import { RightDialogFileInput } from '../../../../../../../../components/RightDialogFileInput';
import { neutralColors } from '../../../../../../../../themes';
import { SystemFileStatuses } from '../../../../../../../../enums/systemFileStatuses';
import { getSystemFilesKeyStart } from '../../../../../../../../features/systemFiles/getSystemFiles/slices';
import UseSystemFile from '../../../../../../../../hooks/UseSystemFile';
import usePagination from '../../../../../../../../hooks/UsePagination';

const styles = {
  regulationCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1rem',
    backgroundColor: neutralColors.neutralColdWhite,
    padding: '1rem',
    borderRadius: '1rem',
  },
  regulationHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  regulationTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
  documents: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
};

const RegulationComponent = ({
  regulation,
  teamGroupId,
  refreshRegulations,
  ...props
}) => {
  const intl = useIntl();
  const { contract } = useParams();
  const dispatch = useDispatch();
  const [refreshState, setRefreshState] = useState(false);

  const [isOpenScreenRightDialog, setIsOpenScreenRightDialog] = useState(false);

  const regulationDocuments = useSelector(state => state.systemFiles);

  const { pagination, handleChangePage, paginationCount } = usePagination({
    defaultRowPerPage: 4,
    dataLength: regulationDocuments.success
      ? regulationDocuments.systemFiles.count
      : 0,
  });

  useEffect(() => {
    dispatch(
      getSystemFilesKeyStart({
        teamGroupId: contract,
        codes: [regulation.code],
        statuses: [SystemFileStatuses.ACTIVE, SystemFileStatuses.INACTIVE],
        pagination,
      }),
    );
  }, [refreshState, pagination.page]);

  const onFileUpdated = () => {
    setIsOpenScreenRightDialog(false);
    setRefreshState(!refreshState);
  };

  const { updateSystemFile } = UseSystemFile({
    onFileUpdated,
    onFileDeleted: () => {},
    overrideDeleteSystemFileKey: 'deleteSystemFiles',
    overrideUpdateSystemFileKey: 'updateSystemFiles',
  });

  const openRegulationUpload = () => {
    setIsOpenScreenRightDialog(true);
  };

  const updateFile = files => {
    updateSystemFile(files[0], regulation.code, { teamGroup: teamGroupId });
  };

  const renderRegulationDocuments = () => {
    return regulationDocuments.systemFiles.results.map((document, index) => {
      return (
        <>
          {index !== 0 && <Divider />}
          <RegulationDocument
            key={document.id}
            document={document}
            showStatus={regulation.multiple}
          />
        </>
      );
    });
  };

  return (
    <div className={props.classes.regulationCard}>
      <div className={props.classes.regulationHeader}>
        <div className={props.classes.regulationTitle}>
          <DocumentIcon />
          <Typography variant={'h3'} component={'h3'}>
            {regulation.label}
          </Typography>
        </div>

        {regulationDocuments && regulationDocuments.success && (
          <Button
            variant={'outlined'}
            color={'primary'}
            onClick={openRegulationUpload}
          >
            {regulationDocuments.systemFiles.results.length === 0
              ? intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.regulations.addDocument',
                })
              : intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.regulations.updateDocument',
                })}
          </Button>
        )}
      </div>

      {(!regulationDocuments || regulationDocuments.loading) && (
        <Loader centered />
      )}
      {regulationDocuments &&
        regulationDocuments.success &&
        regulationDocuments.systemFiles.results.length === 0 && (
          <Typography
            variant={'body1'}
            style={{ color: neutralColors.neutral600 }}
          >
            {intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.regulations.emptyRegulations',
            })}
          </Typography>
        )}
      {regulationDocuments && regulationDocuments.success && (
        <div className={props.classes.documents}>
          {renderRegulationDocuments()}
        </div>
      )}

      {regulationDocuments &&
        regulationDocuments.success &&
        regulation &&
        regulation.multiple && (
          <Pagination
            count={paginationCount}
            page={pagination.page}
            onChange={handleChangePage}
            shape='rounded'
          />
        )}

      <RightDialogFileInput
        open={isOpenScreenRightDialog}
        title={regulation.label}
        authorizedFileTypes={['PDF']}
        setOpen={value => setIsOpenScreenRightDialog(value)}
        alertComponent={regulation.alert}
        onValid={updateFile}
      />
    </div>
  );
};

export default withStyles(styles)(RegulationComponent);
