import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import createSimulationRewardAllocationStore from '../../../../../../store/simulateRewardAllocation/store';
import { PointCommandIds } from '../../../../../../enums';
import { MainRewardConvertCard } from '../MainRewardConvertCard';
import { RewardConvertCard } from '../RewardConvertCard';
import { RewardHelperAboutDialog } from '../RewardHelperAboutDialog';
import useCommandReward from '../../../../../../hooks/CommandReward/UseCommandReward';

const RewardCard = ({ reward, ...props }) => {
  const store = createSimulationRewardAllocationStore();
  const [showComponent, setShowComponent] = useState(false);
  const [disableConvert, setDisableConvert] = useState(false);
  const [isOpenRewardHelperAbout, setIsOpenRewardHelperAbout] = useState(false);
  const { ComponentDecoration, cardDetails, HelperAbout } =
    useCommandReward(reward);

  useEffect(() => {
    setTimeout(() => {
      setShowComponent(true);
    }, 100);
  }, []);

  return (
    <Provider store={store}>
      {showComponent && reward.uuid === PointCommandIds.KSC && (
        <MainRewardConvertCard
          reward={reward}
          setDisableConvert={setDisableConvert}
          setOpenHelperAbout={setIsOpenRewardHelperAbout}
          {...props}
        />
      )}
      {showComponent && reward.uuid !== PointCommandIds.KSC && (
        <RewardConvertCard
          reward={reward}
          setDisableConvert={setDisableConvert}
          cardDetails={cardDetails}
          ComponentDecoration={ComponentDecoration}
          setOpenHelperAbout={setIsOpenRewardHelperAbout}
          {...props}
        />
      )}

      {/*<RewardHelperAboutDialog title={'test'} setOpen={setIsOpenRewardHelperAbout} open={isOpenRewardHelperAbout}*/}
      {/*                         disableConvert={disableConvert} onConvert={props.openConvertPointView} AboutComponent={HelperAbout} />*/}
    </Provider>
  );
};

export default RewardCard;
