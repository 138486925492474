import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../auth';
import { useEffect, useMemo } from 'react';
import { simulateRewardsAllocationsKeyStart } from '../features/rewardAllocations/simulateRewardAllocation/slices';
import { PointCommandIds } from '../enums';

const UseRewardConvertCard = reward => {
  const dispatch = useDispatch();
  const { hierarchyNodeUser } = useAuth();
  const simulationRewardAllocation = useSelector(state => state.simulationKey);

  const defaultPoint = hierarchyNodeUser.point_balance.available;

  const disableConvert = useMemo(() => {
    return (
      (reward.uuid === PointCommandIds.CKU &&
        (!hierarchyNodeUser ||
          !hierarchyNodeUser.cku ||
          hierarchyNodeUser.cku.order_in_progress)) ||
      !simulationRewardAllocation.simulation ||
      !simulationRewardAllocation.simulation.max_points_usable ||
      !simulationRewardAllocation.simulation.order_suggested.points
    );
  }, [simulationRewardAllocation.simulation, hierarchyNodeUser, reward]);

  useEffect(() => {
    if (!simulationRewardAllocation || !defaultPoint) return;

    dispatch(
      simulateRewardsAllocationsKeyStart({
        rewardAllocationId: reward._context.active_allocation.uuid,
        pointToConvert: defaultPoint,
        userId: hierarchyNodeUser.uuid,
      }),
    );
  }, [defaultPoint, reward._context, hierarchyNodeUser.uuid]);

  return { simulationRewardAllocation, defaultPoint, disableConvert };
};

export default UseRewardConvertCard;
