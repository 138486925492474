import React from 'react';
import { Route } from 'react-router-dom';

import { GuestLayout } from './index';
import { AuthProvider } from '../../../../auth';

const GuestRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <AuthProvider disableAutoRedirect={true}>
          <GuestLayout component={Component} {...props} />
        </AuthProvider>
      )}
    />
  );
};

export default GuestRoutes;
