import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { CardAction } from '../../../../components/CardAction';

import UserBoldOctoStyle from '../../../../assets/img/user-bold-octo-style.svg';
import CupBoldOctoStyle from '../../../../assets/img/cup-bold-octo-style.svg';
import { withStyles } from '@mui/styles';
import { createTheme } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [createTheme().breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
};

const AdminHome = ({ ...props }) => {
  const intl = useIntl();
  const { contract } = useParams();
  const history = useHistory();

  return (
    <div className={props.classes.root}>
      <Typography variant={'h1'} component={'h1'} className={'underline-left'}>
        {intl.formatMessage({ id: 'spider.home.welcome' })}
      </Typography>

      <div className={props.classes.actions}>
        <CardAction
          title={intl.formatMessage({
            id: 'spider.home.admin.cardActions.addParticipant.title',
          })}
          description={intl.formatMessage({
            id: 'spider.home.admin.cardActions.addParticipant.description',
          })}
          buttonLabel={intl.formatMessage({
            id: 'spider.home.admin.cardActions.addParticipant.buttonLabel',
          })}
          onClick={() => {
            history.push(`/nodes/${contract}/users`);
          }}
          svgStyle={UserBoldOctoStyle}
        />
        <CardAction
          title={intl.formatMessage({
            id: 'spider.home.admin.cardActions.distributePoints.title',
          })}
          description={intl.formatMessage({
            id: 'spider.home.admin.cardActions.distributePoints.description',
          })}
          buttonLabel={intl.formatMessage({
            id: 'spider.home.admin.cardActions.distributePoints.buttonLabel',
          })}
          onClick={() => {
            history.push(`/nodes/${contract}/points`);
          }}
          svgStyle={CupBoldOctoStyle}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(AdminHome);
