import React from 'react';
import { Box, withStyles, Typography, Link } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { PointTransaction } from '../../../features/lists/points/types';

const styles = {};

/**
 *
 * @param {{transaction: PointTransaction}} props
 */
const Component = ({ transaction }) => {
  const intl = useIntl();
  const { sub_type, source } = transaction;
  switch (sub_type) {
    case 'EXPIRATION':
      return '-';
    case 'LOAD':
      return (
        <Box>
          <Typography>
            {intl.formatMessage({ id: 'spider.common.loading' })}
          </Typography>
        </Box>
      );
    case 'ORDER':
      return (
        <Box>
          <Typography>Commande</Typography>
          {Boolean(source?.name) && (
            <Link component={RouterLink} to='/'>
              <Typography
                variant='subtitle1'
                style={{ fontSize: '0.75rem', whiteSpace: 'nowrap' }}
              >
                <span>Ref </span>
                <span>{source.name}</span>
              </Typography>
            </Link>
          )}
        </Box>
      );
    case 'ORDER_CANCELLATION':
      return (
        <Box>
          <Typography>Annulation de commande</Typography>
          {Boolean(source?.name) && (
            <Link component={RouterLink} to='/'>
              <Typography
                variant='subtitle1'
                style={{ fontSize: '0.75rem', whiteSpace: 'nowrap' }}
              >
                <span>Ref </span>
                <span>{source.name}</span>
              </Typography>
            </Link>
          )}
        </Box>
      );
    case 'WITHDRAWAL':
      return (
        <Box>
          <Typography>Retrait</Typography>
        </Box>
      );
    default:
      return <Typography>{sub_type}</Typography>;
  }
};

const SubType = withStyles(styles)(Component);
export { SubType };
