import React from 'react';
import { PointTransaction } from '../../../features/lists/points/types';
import { OperationType } from '../../../enums/operationType';
import { neutralColors, systemColors } from '../../../themes';
import {
  CalendarRemove,
  MessageQuestion,
  TrendDown,
  TrendUp,
} from 'iconsax-react';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@mui/styles';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  },
};

/**
 * @param {{transaction: PointTransaction}} props
 */
const TransactionComponent = ({ transaction, classes }) => {
  const { amount, sub_type } = transaction;
  const { Icon, label, color } = getType(amount, sub_type);

  return (
    <Box className={classes.root}>
      <Icon color={color} size='1.75rem' />
      <Typography>{label}</Typography>
    </Box>
  );
};

const TransactionType = withStyles(styles)(TransactionComponent);
export { TransactionType };

/**
 * @param {number} amount
 * @param {OperationType} sub_type
 * @returns {{label: string, Icon: React.Component, color: string}}
 */
function getType(amount, sub_type) {
  switch (true) {
    case sub_type === 'EXPIRATION':
      return {
        label: 'Expiré',
        color: systemColors.errorRegular,
        Icon: CalendarRemove,
      };
    case amount > 0:
      return {
        label: 'Crédit',
        color: systemColors.successRegular,
        Icon: TrendUp,
      };
    case amount <= 0:
      return {
        label: 'Débit',
        color: systemColors.errorRegular,
        Icon: TrendDown,
      };
    default:
      return {
        label: 'Inconnu',
        color: neutralColors.neutral500,
        Icon: MessageQuestion,
      };
  }
}
