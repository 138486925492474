import instance from '../instance';

const baseUrl = 'reward-allocations/';

const rewardAllocations = {
  update(id, rewardAllocations) {
    const url = `${baseUrl}${id}/`;
    return instance.put(url, rewardAllocations);
  },
  updateImage(id, image, code) {
    const url = `${baseUrl}${id}/custom-image/`;
    return instance.post(url, image, {
      params: {
        code,
      },
    });
  },
  deleteImage(id, image) {
    const url = `${baseUrl}${id}/custom-image/`;
    return instance.delete(url, image);
  },
  simulate(id, points, hierarchyNodeUserId) {
    const url = `${baseUrl}${id}/simulate/`;
    return instance.post(url, {
      points,
      hierarchy_node_user: hierarchyNodeUserId,
    });
  },
};

export default rewardAllocations;
