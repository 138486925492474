import React from 'react';
import image from '../../../assets/img/avatar.png';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@mui/styles';
import { HierarchyNodeUser } from '../../../features/lists/participants/types';

const styles = {
  container: {
    display: 'flex',
    gap: '.75rem',
    alignItems: 'center',
  },
  image: {
    width: '2.8rem',
    height: '2.8rem',
  },
  name: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  email: {
    fontSize: '0.75rem',
  },
};

/**
 * @param {{owner: HierarchyNodeUser}} props
 */
const ParticipantAvatar = ({ owner, classes }) => {
  const { firstname, lastname, email } = owner;

  return (
    <Box className={classes.container}>
      <img src={image} className={classes.image} />
      <div>
        <Typography variant='h6' style={{ fontSize: '1rem' }}>
          {firstname} {lastname}
        </Typography>
        <Typography className={classes.email}>{email}</Typography>
      </div>
    </Box>
  );
};

const Participant = withStyles(styles)(ParticipantAvatar);

export { Participant };
