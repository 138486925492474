import { all } from 'redux-saga/effects';

import { default as updateRewardAllocationsSaga } from '../../features/rewardAllocations/updateRewardAllocations/sagas';
import { default as updateRewardAllocationsImageSaga } from '../../features/rewardAllocations/updateRewardAllocationsImage/sagas';

export default function* rewardAllocationsSaga() {
  yield all([
    updateRewardAllocationsSaga(),
    updateRewardAllocationsImageSaga(),
  ]);
}
