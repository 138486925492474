import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles } from '@mui/styles';
import TextField from '../../../../../../components/TextField/TextField';
import useDirtyPristine from '../../../../../../hooks/UseDirtyPristine';
import Button from '../../../../../../components/Button';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import useHandleToast from '../../../../../../hooks/UseHandleToast';
import { useHistory } from 'react-router-dom';
import { AdministrationUrl } from '../../../../../../enums/administrationUrl';
import {
  patchTeamGroupKeyClear,
  patchTeamGroupKeyStart,
} from '../../../../../../features/teamGroup/patchTeamGroup/slices';
import { neutralColors } from '../../../../../../themes';
import { createTheme } from '@material-ui/core/styles';
import { Loader } from '../../../../../../../components';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  platformId: {
    display: 'flex',
    flexDirection: 'column',
  },
  platformCode: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [createTheme().breakpoints.up('md')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  platformCodeResult: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const PlatformParameters = ({ refreshState, ...props }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { dirtyChange, resetPristinePage } = useDirtyPristine();

  const useTeamGroup = useSelector(state => state.getTeamGroupKey);
  const patchTeamGroupKey = useSelector(state => state.patchTeamGroupKey);

  const [platformCode, setPlatformCode] = useState(useTeamGroup.teamGroup.code);
  const maxPlatformCodeLength = 64;

  useHandleToast(
    patchTeamGroupKey,
    dispatch,
    patchTeamGroupKeyClear,
    'spider.hierarchy_node.platform_customization.parameters.platform.update.success',
    'spider.hierarchy_node.platform_customization.parameters.platform.update.error',
  );

  const titleFieldChange = e => {
    dirtyChange();
    setPlatformCode(e.target.value.replaceAll(' ', '-').toLowerCase());
  };

  const savePlatformParameters = () => {
    resetPristinePage();
    dispatch(
      patchTeamGroupKeyStart({
        teamGroup: {
          baseCode: useTeamGroup.teamGroup.code,
          code: platformCode,
        },
      }),
    );
  };

  useEffect(() => {
    if (patchTeamGroupKey.success) {
      history.push(
        `/nodes/${platformCode}/administration/${AdministrationUrl.PARAMETERS}`,
        { shallow: true },
      );
      refreshState();
    }
  }, [patchTeamGroupKey]);

  return patchTeamGroupKey.loading ? (
    <Loader centered />
  ) : (
    <Formsy
      name={'parameter-platform'}
      onValidSubmit={savePlatformParameters}
      className={props.classes.root}
    >
      <Typography variant={'h4'} component={'h4'}>
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.platform.title',
        })}
      </Typography>

      <div className={props.classes.platformId}>
        <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
          {useTeamGroup.teamGroup.code}
        </Typography>
        <Typography
          variant={'body1'}
          style={{ color: neutralColors.neutral700 }}
        >
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.platform.platformIdLabel',
          })}
        </Typography>
      </div>

      <div className={props.classes.platformCode}>
        <TextField
          name={'platformCode'}
          value={platformCode}
          label={intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.platform.platformNameLabel',
          })}
          variant={'outlined'}
          required
          style={{ width: '60%' }}
          validations={{
            maxLength: maxPlatformCodeLength,
            isSlug: platformCode,
          }}
          validationErrors={{
            isDefaultRequiredValue: intl.formatMessage({
              id: 'common.form.required_error',
            }),
            maxLength: intl
              .formatMessage({
                id: 'common.form.max_length_custom_error',
              })
              .format(maxPlatformCodeLength),
            isSlug: intl.formatMessage({
              id: 'common.form.is_slug',
            }),
          }}
          onChange={titleFieldChange}
        />
        <div className={props.classes.platformCodeResult}>
          <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
            {`incentive.edenred.fr/${platformCode}`}
          </Typography>
          <Typography
            variant={'body1'}
            style={{ color: neutralColors.neutral700 }}
          >
            {intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.parameters.platform.platformUrlLabel',
            })}
          </Typography>
        </div>
      </div>

      <div className={props.classes.actions}>
        <Button
          color={'primary'}
          variant={'outlined'}
          size={'small'}
          type={'submit'}
        >
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.platform.saveButtonLabel',
          })}
        </Button>
      </div>
    </Formsy>
  );
};

export default withStyles(styles)(PlatformParameters);
