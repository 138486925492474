import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { RowsPerPageSelector } from '../RowsPerPageSelector';
import UsePagination from '../../hooks/UsePagination';
import { formatDate } from '../../../helpers/DateHelper';
import { CircularProgress, Drawer, Pagination } from '@mui/material';
import { systemColors } from '../../themes';
import { useLazyGetOrdersListQuery } from '@src/data/api/endpoints/orders';
import OrderStatus from '../OrderStatus';
import { Button } from '@spider:src/components';
import { ArrowRight } from 'iconsax-react';
import { ProductType } from './components/ProductType';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';

const styles = {
  stickyHeader: {
    position: 'sticky',
    right: 0,
    zIndex: 3,
  },
  stickyColumn: {
    position: 'sticky',
    right: 0,
    background: 'white',
    zIndex: 2,
  },
};

/** @param {{queryParams: import("@async-calls/orders").OrdersListParams}} */
export const OrdersList = ({ queryParams }) => {
  const { open, onOpen, onClose } = useDisclosure();
  const intl = useIntl();
  const [fetch, { data, isFetching, error }] = useLazyGetOrdersListQuery();
  const {
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationCount,
  } = UsePagination({
    dataLength: data?.count ? data?.count : 0,
  });

  useEffect(() => {
    fetch({
      ...queryParams,
      page: pagination.page,
      page_size: pagination.rowsPerPage,
    });
  }, [pagination.page, pagination.rowsPerPage]);


  const columns = ['date', 'product', 'ref', 'total', 'status'].map(el => ({
    field: el,
    label: intl.formatMessage({
      id: `spider.orders.tabs.list.table.${el}`,
    }),
  }));

  return (
    <>
      <Box display='flex' flexDirection='column'>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            {isFetching && <CircularProgress color='primary' size='1.2rem' />}
            {Boolean(error) && (
              <Typography style={{ color: systemColors.errorRegular }}>
                {error?.message}
              </Typography>
            )}
          </Box>
          <RowsPerPageSelector
            rowsPerPage={pagination.rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
        <TableContainer
          component={Paper}
          style={{ maxWidth: '100%', overflow: 'scroll', maxHeight: '50dvh' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map(column => {
                  return (
                    <TableCell
                      key={column.field}
                      style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}
                <TableCell
                  style={{
                    ...styles.stickyHeader,
                    wordBreak: 'keep-all',
                    whiteSpace: 'nowrap',
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Boolean(data?.results.length) &&
                data.results.map(order => (
                  <TableRow key={order.uuid}>
                    <TableCell>
                      {' '}
                      <Typography>
                        {formatDate(order.date).replaceAll('/', '.')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <ProductType product={order.product} />
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{order.ref}</Typography>
                        <Typography style={{ fontSize: '0.75rem' }}>
                          {order.orderNumber}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{order.total}</Typography>
                        <Typography style={{ fontSize: '0.75rem' }}>
                          {order.points}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <OrderStatus status={order.status} />
                    </TableCell>
                    <TableCell style={styles.stickyColumn}>
                      <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={onOpen}
                      >
                        <ArrowRight />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display='flex' width='100%' justifyContent='center' mt='1.5rem'>
          <Pagination
            count={paginationCount}
            onChange={handleChangePage}
            variant='outlined'
          />
        </Box>
      </Box>
      <Drawer anchor='right' open={open} onClose={onClose}></Drawer>
    </>
  );
};
