import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { RowsPerPageSelector } from '../RowsPerPageSelector';
import UsePagination from '../../hooks/UsePagination';
import { formatDate } from '../../../helpers/DateHelper';
import { Participant } from './components/Participant';
import { amountStyles } from '../../helper/styles';
import { TransactionType } from './components/TransactionType';
import { CircularProgress, Pagination } from '@mui/material';
import { SubType } from './components/SubType';
import { systemColors } from '../../themes';
import { useLazyGetPointsTransactionsListQuery } from '@src/data/api/endpoints/pointsTransactions';

/**
 * @param {{queryParams: import("@async-calls/pointsTransactions").PointsListParams}} props
 */
export const PointsList = ({ queryParams }) => {
  const intl = useIntl();
  const [fetch, { data, isFetching, error }] =
    useLazyGetPointsTransactionsListQuery();
  const {
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationCount,
  } = UsePagination({
    dataLength: data?.count ? data?.count : 0,
  });

  useEffect(() => {
    fetch({
      ...queryParams,
      page: pagination.page,
      page_size: pagination.rowsPerPage,
    });
  }, [pagination.page, pagination.rowsPerPage]);

  const columns = [
    'date',
    'matricule',
    'participant',
    'type',
    'sub_type',
    'points',
  ].map(el => ({
    field: el,
    label: intl.formatMessage({
      id: `spider.points.tabs.list.table.${el}`,
    }),
  }));

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' justifyContent='space-between'>
        <Box>
          {isFetching && <CircularProgress color='primary' size='1.2rem' />}
          {Boolean(error) && (
            <Typography style={{ color: systemColors.errorRegular }}>
              {error?.message}
            </Typography>
          )}
        </Box>
        <RowsPerPageSelector
          rowsPerPage={pagination.rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
      <TableContainer
        component={Paper}
        style={{ maxWidth: '100%', overflow: 'scroll', maxHeight: '50dvh' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map(column => {
                return (
                  <TableCell
                    key={column.field}
                    style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(data?.results.length) &&
              data.results.map(tr => (
                <TableRow key={tr.uuid}>
                  <TableCell>
                    <p>{formatDate(tr.date).replaceAll('/', '.')}</p>
                  </TableCell>
                  <TableCell>
                    <p>{tr.owner.first_identifier}</p>
                  </TableCell>
                  <TableCell>
                    <Participant transaction={tr} />
                  </TableCell>
                  <TableCell>
                    <TransactionType transaction={tr} />
                  </TableCell>
                  <TableCell>
                    <SubType transaction={tr} />
                  </TableCell>
                  <TableCell>
                    <Typography style={amountStyles(tr.amount > 0)}>
                      {tr.amount}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display='flex' width='100%' justifyContent='center' mt='1.5rem'>
        <Pagination
          count={paginationCount}
          onChange={handleChangePage}
          variant='outlined'
        />
      </Box>
    </Box>
  );
};
