import instance from '../instance';
import '../../../helpers/DateHelper';

const baseUrl = 'rewards/';

const rewards = {
  active(name, categoryId) {
    const hasName = name != null && name !== '';
    var url = `${baseUrl}active/`;
    if (hasName || categoryId) url += '?';
    if (hasName) url = `${url}name=${name}`;
    if (hasName && categoryId) url += '&';
    if (categoryId) url += `category=${categoryId}`;
    return instance.get(url);
  },
  create(reward) {
    return instance.post(baseUrl, reward);
  },
  exportOrders(
    categoryId,
    teamId,
    collaboratorId,
    periodId,
    validationStart,
    validationEnd,
  ) {
    var url = `${baseUrl}export-orders/`;
    var hasParam = false;
    if (
      categoryId ||
      teamId ||
      collaboratorId ||
      periodId ||
      validationStart ||
      validationEnd
    )
      url += '?';
    if (categoryId) {
      url += `category=${categoryId}`;
      hasParam = true;
    }
    if (teamId) {
      if (hasParam) url += '&';
      url += `team=${teamId}`;
      hasParam = true;
    }
    if (collaboratorId) {
      if (hasParam) url += '&';
      url += `collaborator=${collaboratorId}`;
      hasParam = true;
    }
    if (periodId) {
      if (hasParam) url += '&';
      url += `period=${periodId}`;
      hasParam = true;
    }
    if (validationStart) {
      if (hasParam) url += '&';
      url += `validationStart=${validationStart.toUTCJSON2()}`;
      hasParam = true;
    }
    if (validationEnd) {
      if (hasParam) url += '&';
      url += `validationEnd=${validationEnd.toUTCJSON2()}`;
      hasParam = true;
    }
    return instance.get(url);
  },
  get(id) {
    const url = `${baseUrl}${id}/`;
    return instance.get(url);
  },
  replaceImage(id, rewardImage) {
    const url = `${baseUrl}${id}/image/`;
    return instance.post(url, rewardImage);
  },
  update(id, reward) {
    const url = `${baseUrl}${id}/`;
    return instance.put(url, reward);
  },
  updateActivation(id, isActive) {
    const url = `${baseUrl}${id}/`;
    return instance.patch(url, { isActive });
  },
  list(teamId, distributor, allocatedOnly) {
    const params = new URLSearchParams();
    if (distributor) {
      params.append('distributor', distributor);
    }
    if (allocatedOnly) {
      params.append('allocated_only', 'true');
    }
    params.append('page_size', '4');
    const url = `hierarchy-nodes/${teamId}/${baseUrl}`;
    return instance.get(url, { params });
  },
  rewardOrder(hierarchyNodeUserId, form, items, lfss_form) {
    const data = {
      hierarchy_node_user: hierarchyNodeUserId,
      items,
    };

    if (form) {
      data.form = form;
    }
    if (lfss_form) {
      data.lfss_form = lfss_form;
    }

    const url = `reward-orders/`;
    return instance.post(url, data);
  },
};

export default rewards;
