import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Avatar,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  ArrowDown2,
  ArrowSwapHorizontal,
  ArrowUp2,
  Logout,
} from 'iconsax-react';

import { HierarchyNodeUserSwitcher } from './components/HierarchyNodeUserSwitcher';
import { UserRoleName } from '../../../../../../Localization';
import { neutralColors } from '../../../../../../../themes/colors';
import { useAuth } from '../../../../../../../../auth';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    gap: '8px',
    padding: '8px',
  },
  username: {
    color: neutralColors.neutral900,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  userDetails: {
    color: neutralColors.neutral500,
    fontSize: '10px',
    lineHeight: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  menuPaper: {
    backgroundColor: neutralColors.neutralWhite,
    borderRadius: '8px',
    boxShadow: '0px 6px 10px 2px rgba(10, 31, 94, 0.12)',
    padding: '0px',
  },
  menuList: {
    alignItems: 'flex-start',
    display: 'inline-flex',
    flexDirection: 'column',
    padding: '8px',
  },
  menuItem: {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    padding: '8px 16px',
    '&:focus': {
      background: 'transparent !important',
      backgroundColor: 'transparent !important',
      border: 'none !important',
    },
    '&:hover': {
      background: 'transparent !important',
      backgroundColor: 'transparent !important',
    },
  },
  title: {
    textAlign: 'center',
  },
}));

const Account = () => {
  const { hierarchyNodeUser, logout } = useAuth();
  const intl = useIntl();
  const anchorRef = useRef(null);
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [openSwitcher, setOpenSwitcher] = useState(false);
  const hierarchyNodeUserInitials =
    hierarchyNodeUser?.firstname?.charAt(0).toUpperCase() +
    hierarchyNodeUser?.lastname?.charAt(0).toUpperCase();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleCloseListener = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const logoutUser = async () => {
    handleClose();
    await logout();
    window.location.reload();
  };

  const switchUser = () => {
    setOpenSwitcher(true);
    handleClose();
  };

  return (
    <>
      <div ref={anchorRef} className={classes.root} onClick={handleToggle}>
        <Avatar>{hierarchyNodeUserInitials}</Avatar>

        <div>
          <Typography variant={'body1'} className={classes.username}>
            {hierarchyNodeUser?.firstname} {hierarchyNodeUser?.lastname}
          </Typography>

          <Typography className={classes.userDetails}>
            <UserRoleName role={hierarchyNodeUser?.role} />{' '}
            {hierarchyNodeUser?.first_identifier
              ? ` - ${hierarchyNodeUser?.first_identifier}`
              : ''}
          </Typography>
        </div>

        {!open && <ArrowDown2 size={24} />}
        {open && <ArrowUp2 size={24} />}
      </div>

      <Popper
        anchorEl={anchorRef.current}
        open={open}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.menuPaper}>
              <ClickAwayListener onClickAway={handleCloseListener}>
                <MenuList
                  id='menu-list-grow'
                  onKeyDown={handleListKeyDown}
                  className={classes.menuList}
                >
                  <MenuItem onClick={switchUser} className={classes.menuItem}>
                    <ArrowSwapHorizontal size={24} />
                    {intl.formatMessage({
                      id: 'spider.account.switch_profile',
                    })}
                  </MenuItem>

                  <MenuItem
                    onClick={logoutUser}
                    className={`${classes.menuItem}`}
                  >
                    <Logout size={24} />
                    {intl.formatMessage({ id: 'spider.account.logout' })}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <HierarchyNodeUserSwitcher
        open={openSwitcher}
        setOpen={setOpenSwitcher}
      />
    </>
  );
};

export default Account;
