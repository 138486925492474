import { configureStore } from '@reduxjs/toolkit';
import updateRewardAllocationsReducer from '../../features/rewardAllocations/updateRewardAllocations/slices';
import updateRewardAllocationsImageReducer from '../../features/rewardAllocations/updateRewardAllocationsImage/slices';
import createSagaMiddleWare from 'redux-saga';
import Sagas from './sagas';

const createRewardAllocationsStore = () => {
  const sagaRewardAllocations = createSagaMiddleWare();

  const store = configureStore({
    reducer: {
      updateRewardsAllocations:
        updateRewardAllocationsReducer.updateRewardsAllocationsKey,
      updateRewardsAllocationsImage:
        updateRewardAllocationsImageReducer.updateRewardsAllocationsImageKey,
    },
    middleware: () => [sagaRewardAllocations],
  });

  sagaRewardAllocations.run(Sagas);

  return store;
};

export default createRewardAllocationsStore;
