import { Box } from '@material-ui/core';
import { getIntlEnumLabel } from '@src/Spider/enums/utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { Info } from './components';
import { InfoCircle, UserOctagon } from 'iconsax-react';
/** @typedef {import("@async-calls/hierarchyNodeUsers").UserBodyDetailed} UserBodyDetailed */

/** @param {{user: UserBodyDetailed, className: string}} props */
export const DetailsTab = ({ user }) => {
  const intl = useIntl();

  const generalInfos = [
    { label: 'matricule', value: user.first_identifier },
    { label: 'firstname', value: user.firstname },
    { label: 'lastname', value: user.lastname },
    {
      label: 'type',
      value: intl.formatMessage({
        id: getIntlEnumLabel('participant_type', user.type),
      }),
    },
    { label: 'contactEmail', value: user.email },
    { label: 'creationDate', value: '?????' },
    { label: 'lastUpdated', value: '?????' },
  ];

  const managerInfos = [
    { label: 'matricule', value: user.manager?.first_identifier },
    { label: 'firstname', value: user.manager?.firstname },
    { label: 'lastname', value: user.manager?.lastname },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Info.Card title='Informations générales' icon={<InfoCircle />}>
        {generalInfos.map(({ value, label }, i) => (
          <Info.Item
            key={i}
            value={value}
            label={intl.formatMessage({
              id: `spider.participants.details.generalInfos.${label}`,
            })}
          />
        ))}
      </Info.Card>
      {Boolean(user.manager) && (
        <Info.Card title='Responsable' icon={<UserOctagon />}>
          {managerInfos.map(({ value, label }, i) => (
            <Info.Item
              key={i}
              value={value}
              label={intl.formatMessage({
                id: `spider.participants.details.managerInfos.${label}`,
              })}
            />
          ))}
        </Info.Card>
      )}
    </Box>
  );
};
