import React from 'react';
import { withStyles } from '@mui/styles';
import { ReactComponent as Ellipse } from '../../../../assets/img/ellipse.svg';
import { ReactComponent as InfoCircle } from '../../../../assets/img/homeParticipantsConvert/info-circle.svg';
import { Button } from '../../../index';
import { Paper, Typography } from '@material-ui/core';
import { useAuth } from '../../../../../auth';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ArrowRight } from 'iconsax-react';
import { useSelector } from 'react-redux';
import { neutralColors } from '../../../../themes';

const styles = {
  root: {
    '& .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.5rem',
      boxShadow: 'none !important',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 288,
      overflow: 'clip',
      position: 'relative',
      padding: '0.5rem 1rem',
      borderRadius: '1rem',
    },
  },
  points: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: '2',
    paddingLeft: '1.5rem',
  },
  pointsDetails: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
  },
  ellipse: {
    zIndex: '1',
    position: 'absolute',
    width: '9rem',
    height: '4rem',
  },
};

const PointConverterShortcut = ({ ...props }) => {
  const history = useHistory();
  const intl = useIntl();
  const { hierarchyNodeUser } = useAuth();
  const { contract } = useParams();

  const mainState = useSelector(state => state.mainKey);

  const goToHome = () => {
    history.push(`/nodes/${contract}/home`);
  };

  return (
    <div className={props.classes.root}>
      <Ellipse
        className={props.classes.ellipse}
        fill={mainState.config.mainColor.value}
      />
      <Paper>
        <div className={props.classes.points}>
          <Typography
            variant={'h2'}
            component={'h2'}
            style={{ fontWeight: 'bold', color: neutralColors.neutralWhite }}
          >
            {hierarchyNodeUser?.point_balance?.available?.toFormatNumber(false)}
          </Typography>
          <div className={props.classes.pointsDetails}>
            <Typography
              variant={'body2'}
              style={{ color: neutralColors.neutralWhite }}
            >
              {intl.formatMessage({ id: 'spider.convertShortcut.points' })}
            </Typography>
            <InfoCircle fill={neutralColors.neutralWhite} />
          </div>
        </div>
        <Button
          color={'primary'}
          variant={'contained'}
          size={'small'}
          onClick={goToHome}
          style={{ padding: '0.25rem 0.5rem', height: '2rem' }}
        >
          {intl.formatMessage({ id: 'spider.convertShortcut.convertAction' })}
          <ArrowRight size='16' />
        </Button>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(PointConverterShortcut);
