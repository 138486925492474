import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HambergerMenu } from 'iconsax-react';
import {
  AppBar as MuiAppBar,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
} from '@material-ui/core';

import { Account, MobileMenu } from './components';
import LogoImg from '../../../../../assets/img/logo.svg';
import { themeColors } from '../../../../../themes/colors';

const useStyles = makeStyles(theme => ({
  hidden: {
    display: 'none',
  },
  iconButton: {
    backgroundColor: themeColors.primaryContrastText,
    color: themeColors.primaryRegular,
  },
}));

const AppBar = ({ ...props }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(location.pathname);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleListeItemClick = (event, index) => {
    setSelectedIndex(index);
    setMobileOpen(false);
  };

  useEffect(() => {
    setSelectedIndex(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <MuiAppBar
        position='relative'
        elevation={0}
        className={mobileOpen ? classes.hidden : ''}
      >
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <img height='48px' src={LogoImg} alt='logo' />
          <Hidden xsDown={true}>
            <Account />
          </Hidden>
          <Hidden smUp={true}>
            <IconButton
              aria-label='open drawer'
              onClick={handleDrawerToggle}
              className={classes.iconButton}
            >
              <HambergerMenu />
            </IconButton>
          </Hidden>
        </Toolbar>
      </MuiAppBar>
      {mobileOpen && (
        <MobileMenu
          handleDrawerToggle={handleDrawerToggle}
          selectedIndex={selectedIndex}
          handleListeItemClick={handleListeItemClick}
          LogoImg={LogoImg}
        />
      )}
    </>
  );
};

export default AppBar;
