import { ReactComponent as CardDetailGlobe } from '../../assets/img/homeParticipantsConvert/card-details-globe.svg';
import { ReactComponent as CardDetailShop } from '../../assets/img/homeParticipantsConvert/card-details-shop.svg';
import { ReactComponent as CardDetailCalendar } from '../../assets/img/homeParticipantsConvert/card-details-calendar.svg';
import { DecorationCard } from '../../scenes/Home/components/UserHome/components/DecorationCard';
import { withStyles } from '@mui/styles';
import React from 'react';
import { PointCommandIds } from '../../enums';
import CommandCard from '../../assets/img/homeParticipantsConvert/ks-card.png';
import { useIntl } from 'react-intl';
import { useAuth } from '../../../auth';
import { ParticipantType } from '../../enums/participantType';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const styles = {
  design: {
    position: 'absolute',
    // width: '30.71875rem',
    height: '5.16231rem',
    // right: '-3.65625rem',
    bottom: '4.84388rem',
  },
  sphere: {
    width: '27.25rem',
    height: '26.0625rem',
    position: 'absolute',
    right: '-3.4375rem',
    bottom: '-13rem',
    borderRadius: '27.25rem',
    opacity: 0.3,
    filter: 'blur(100px)',
    background: 'linear-gradient(135deg, #FAAF0C 0%, #FFDC60 100%)',
  },
};

const useKS = () => {
  const intl = useIntl();
  const id = PointCommandIds.KS;
  const { hierarchyNodeUser } = useAuth();

  // component card
  // form field to show per step
  const CommandCardComponent = () => (
    <img src={CommandCard} alt='empty' style={{ zIndex: 2 }} />
  );

  // CommandDesign={KSDesign}
  const ComponentDecoration = ({ ...props }) => (
    <DecorationCard
      CommandCard={CommandCardComponent}
      classes={props.classes}
      title={intl.formatMessage({ id: 'spider.convertPoints.home.ks.title' })}
    />
  );

  const cardDetails = [
    {
      Icon: CardDetailGlobe,
      label: intl.formatMessage({
        id: 'spider.convertPoints.home.ks.globeLabel',
      }),
    },
    {
      Icon: CardDetailShop,
      label: intl.formatMessage({
        id: 'spider.convertPoints.home.ks.shopLabel',
      }),
    },
    {
      Icon: CardDetailCalendar,
      label: intl.formatMessage({
        id: 'spider.convertPoints.home.ks.calendarLabel',
      }),
    },
  ];

  const getInformationStepDefaultFormData = () => {
    if (hierarchyNodeUser.type === ParticipantType.PHYSIC) {
      return {
        phone: '',
        line1: '',
        line2: '',
        zipCode: '',
        city: '',
      };
    } else if (hierarchyNodeUser.type === ParticipantType.MORALE) {
      return {
        phone: '',
      };
    }
  };

  const HelperAbout = () => (
    <div>
      <div>
        <Typography variant={'h3'} component={'h3'}>
          {"Un catalogue d'offres négociées pour combler vos attentes à 100% !"}
        </Typography>

        <div>
          <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
            {'Pour en profiter, c’est simple :'}
          </Typography>

          <div>Stepper</div>

          <Typography variant={'body1'}>{'Et  voilà !'}</Typography>
          <Typography variant={'body2'} style={{ fontStyle: 'italic' }}>
            {
              "Pour rappel, votre solde est valable pendant un an à partir de l'activation de votre compte."
            }
          </Typography>

          <Link className={'bold large'} onClick={() => {}}>
            {'Tout savoir sur Kadéos Connect'}
          </Link>
        </div>
      </div>
      <div
        style={{
          background: 'linear-gradient(135deg, #FAAF0C 0%, #FFDC60 100%)',
        }}
      >
        <CommandCardComponent />
      </div>
    </div>
  );

  return {
    id,
    cardDetails,
    Card: CommandCardComponent,
    HelperAbout,
    ComponentDecoration: withStyles(styles)(ComponentDecoration),
    informationStep: {
      defaultForm: getInformationStepDefaultFormData(),
      showAndValidateBirthDate: false,
      alert: {
        PP: null,
        PM: null,
      },
      address: {
        PP: true,
        PM: false,
      },
      addressTitle: {
        PP: 'spider.convertPoints.stepper.informationStep.personalAddress.titleDelivery',
        PM: 'spider.convertPoints.stepper.informationStep.personalAddress.deliveryInformation',
      },
    },
  };
};

export default useKS;
