import React, { useEffect, useRef, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import api from '../../../../data/api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => {
  return {
    popup: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2000,
    },

    popupContent: {
      position: 'relative',
      backgroundColor: 'white',
      width: '80%',
      maxWidth: 800,
      height: '80%',
      overflowY: 'auto',
      padding: 20,
      boxShadow: '0 2px 16px rgba(0, 0, 0, 0.25)',
      borderRadius: 8,
    },

    mediaCloseIcon: {
      position: 'absolute',
      color: 'white',
      top: 10,
      right: 10,
      width: 25,
      height: 25,
      fontSize: 20,
      zIndex: 100,
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.main,
        color: 'white',
      },
    },
  };
};

const GoalPopUpEmbed = ({ goalDefinitionId, userId, onClose, classes }) => {
  const [embedId, setEmbedId] = useState(
    'd3703ae8-def6-4ab5-8f65-ef3e6fbe1183'
  );
  const [token, setToken] = useState(null);
  const containerRef = useRef(null);
  const popupRef = useRef(null);

  const fetchToken = async () => {
    try {
      setToken(null);
      if (containerRef.current) containerRef.current.innerHTML = '';

      const response = await api.toucanToken.get(userId, goalDefinitionId);
      console.log('response', response);

      setToken(response.data.access_token);
    } catch (error) {
      console.error('Error fetching Toucan token:', error);
      if (error.response) {
        console.log('Server Response:', error.response);
      }
    }
  };

  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (embedId && token && containerRef.current) {
      const scriptSrc = `https://objow.toucantoco.com/scripts/embedLauncher.js?id=${embedId}&token=${token}`;
      const script = document.createElement('script');
      script.src = scriptSrc;
      script.async = true;
      script.type = 'text/javascript';

      containerRef.current.innerHTML = '';
      containerRef.current.appendChild(script);
    }
  }, [embedId, token, userId, goalDefinitionId]);

  useEffect(() => {
    fetchToken();
  }, [userId, goalDefinitionId]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className={classes.popup}>
      <div ref={popupRef} className={classes.popupContent}>
        <IconButton onClick={onClose} className={classes.mediaCloseIcon}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
        <div
          ref={containerRef}
          key={`${userId}-${token}`}
          style={{ height: '100%' }}
        ></div>
      </div>
    </div>
  );
};

export default withStyles(styles)(GoalPopUpEmbed);
