import { mockedGetOrderDetails, mockedGetOrderList } from '@src/mock/fetching';
import { baseApi } from '@src/store/api-query';

/**
 * @param {string} id ID du noeud hiérarchique en base de données
 */
// const baseUrl = id => `orders/${id}`;

const orders = {
  /**
   * @description Retourne la liste des participants appartenant à un hierarchy node
   * @param {OrdersListParams} queryParams
   */
  list({ id, page, page_size }) {
    const params = new URLSearchParams();
    if (page) params.append('page', page);
    if (page_size) params.append('page_size', page_size);
    return mockedGetOrderList({ id, page, page_size });
  },
  /**
   * @description Retourne la liste des participants appartenant à un hierarchy node
   * @param {{id: string}} queryParams
   */
  details({ id }) {
    return mockedGetOrderDetails({ id });
  },
};

const ordersQueries = baseApi.injectEndpoints({
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<OrdersListParams, typeof builder.query, 'api-query', GetOrderListBody>}*/
    getOrdersList: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => orders.list(params));
      },
    }),
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<{id:string}, typeof builder.query, 'api-query', GetOrderListBody>}*/
    getOrderDetails: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => orders.details(params));
      },
    }),
  }),
});

export const {
  useGetOrdersListQuery,
  useLazyGetOrdersListQuery,
  useGetOrderDetailsQuery,
  useLazyGetOrderDetailsQuery,
} = ordersQueries;

export default orders;

/**
 * @typedef {Object} OrdersListParams
 * @property {string} id ID du noeud hiérarchique en base de données
 * @property {number} [page] Page à retourner
 * @property {number} [page_size] Nombre de résultats à retourner par page
 */
/** @typedef {import('@src/mock/datasets/ordersList').OrderItem} OrderItem */
/** @typedef {{
        results: OrderItem[];
        count: number;
    }} GetOrderListBody */
/** @typedef {{
        data: OrderItem;
    }} GetOrderBody */
