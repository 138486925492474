import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as PointCup } from '../assets/img/points-cup.svg';
import { ReactComponent as PointSummary } from '../assets/img/points-summary.svg';
import { ReactComponent as PointUser } from '../assets/img/points-user.svg';
import { ReactComponent as PointDeclaration } from '../assets/img/points-declaration.svg';
import { neutralColors } from '../themes';
import { useSelector } from 'react-redux';
import { useAuth } from '../../auth';
import { PointCommandIds } from '../enums';

const UseConvertPointsSteps = () => {
  const { hierarchyNodeUser } = useAuth();
  const { contract } = useParams();

  const simulationRewardAllocation = useSelector(
    state => state.simulateRewardsAllocationsKey,
  );
  const stepperData = useSelector(state => state.getStepperDataKey);
  const [steps, setSteps] = useState([]);

  const pointsStep = {
    label: 'Points',
    src: `/nodes/${contract}/conversion-points`,
    icon: <PointCup fill={neutralColors.neutralWhite} />,
  };
  const informationStep = {
    label: 'Informations',
    src: `/nodes/${contract}/informations-personnelles`,
    icon: <PointUser fill={neutralColors.neutralWhite} />,
  };
  const declarationStep = {
    label: 'Declaration',
    src: `/nodes/${contract}/declaration-urssaf`,
    icon: <PointDeclaration fill={neutralColors.neutralWhite} />,
  };
  const summaryStep = {
    label: 'Récapitulatif',
    src: `/nodes/${contract}/recapitulatif-commande`,
    icon: <PointSummary fill={neutralColors.neutralWhite} />,
  };

  useEffect(() => {
    if (!simulationRewardAllocation.simulation) return;

    const steps = [pointsStep];

    if (
      stepperData.commandId &&
      ((!hierarchyNodeUser.cku.last_active_cku &&
        stepperData.commandId.uuid === PointCommandIds.CKU) ||
        stepperData.commandId.uuid !== PointCommandIds.CKU)
    ) {
      steps.push(informationStep);
    }

    if (
      simulationRewardAllocation.simulation &&
      simulationRewardAllocation.simulation.order_suggested.lfss_form_required
    ) {
      steps.push(declarationStep);
    }

    steps.push(summaryStep);

    setSteps(steps);
  }, [
    hierarchyNodeUser,
    stepperData.commandId,
    simulationRewardAllocation.simulation,
  ]);

  return {
    steps,
    sources: steps.map(step => step.src),
    pointsStep,
    informationStep,
    declarationStep,
    summaryStep,
  };
};

export default UseConvertPointsSteps;
