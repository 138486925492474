import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getRewardsKeyStart } from '../../../../features/rewards/getRewards/slices';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { createTheme } from '@material-ui/core/styles';
import { Loader } from '../../../../../components';
import { setCommandId } from '../../../../features/convertPoint/stepperData/slices';
import { RewardCard } from './components/RewardCard';
import { PointCommandIds } from '../../../../enums';

const styles = {
  root: {
    display: 'flex',
    [createTheme().breakpoints.up('md')]: {},
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1.5rem',
  },
  cards: {
    display: 'grid',
    gap: '1.5rem',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [createTheme().breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [createTheme().breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  card: {
    display: 'flex',
    position: 'relative',
  },
};

const UserHome = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { contract } = useParams();

  const rewards = useSelector(state => state.getRewardsKey);

  const rewardFromSessionStorage = sessionStorage.getItem('reward');

  useEffect(() => {
    if (rewardFromSessionStorage) {
      // todo: can automatically or by click retake an order form running
      // dispatch(setCommandId(JSON.parse(rewardFromSessionStorage)))
      // history.push(`/nodes/${contract}/conversion-points`)
    }
    dispatch(
      getRewardsKeyStart({
        teamGroupId: contract,
        allocatedOnly: true,
        distributor: 'Edenred',
      }),
    );
  }, []);

  const openConvertPointView = reward => {
    dispatch(setCommandId(reward));
    sessionStorage.setItem('reward', JSON.stringify(reward));
    history.push(`/nodes/${contract}/conversion-points`);
  };

  const specialReward = useMemo(() => {
    if (!rewards.rewards) return null;
    return (
      rewards.rewards.results.find(
        reward => reward.uuid === PointCommandIds.KSC,
      ) ?? null
    );
  }, [rewards]);

  return (
    <div className={props.classes.root}>
      {/*  todo: banner is link to the operation, cannot do it dynamically */}

      {rewards.loading && <Loader centered />}
      {rewards.success && specialReward && (
        <RewardCard
          key={specialReward.uuid}
          reward={specialReward}
          convert={openConvertPointView}
        />
      )}
      {rewards.success && (
        <div className={props.classes.cards} style={{ width: '100%' }}>
          {rewards.rewards.results.map(reward => {
            return reward.uuid !== PointCommandIds.KSC ? (
              <RewardCard
                key={reward.uuid}
                reward={reward}
                convert={openConvertPointView}
              />
            ) : (
              <></>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(UserHome);
