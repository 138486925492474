import React, { useMemo } from 'react';
import {
  useParams,
  useLocation,
  useHistory,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useGetHierarchyNodeUserDetailsQuery } from '@async-calls/hierarchyNodeUsers';
import {
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { TitleContainer } from '@src/Spider/components/TitleContainer';
import { useIntl } from 'react-intl';
import { ParticipantUrl } from '@src/Spider/enums/participantUrl';
import { TabContext, TabPanel } from '@material-ui/lab';
import { ProfileCard } from './components/ProfileCard';
import { StatusCard } from './components/StatusCard';
import { DetailsTab } from './tabs/Details';
import { PointsList } from '@src/Spider/components/PointsList/PointsList';
import { OwnerEnum } from '@src/Spider/enums/ownerType';
import { OrdersList } from '@src/Spider/components/OrdersList/OrdersList';

const Users = () => {
  /** @type {{contract: string, user_id: string}} */
  const { contract, user_id } = useParams();
  const { data, isSuccess, isLoading } = useGetHierarchyNodeUserDetailsQuery({
    user_id,
    include_address: true,
    include_cku: true,
    include_manager: true,
    include_point_balance: true,
  });
  const intl = useIntl();

  const history = useHistory();
  const location = useLocation();

  const tabPaths = Object.values(ParticipantUrl);
  const tabs = useMemo(
    () =>
      tabPaths.map((el, i) => ({
        label: intl.formatMessage({
          id: `spider.hierarchy_node_user_pages.tabs.${el}`,
        }),
        index: i,
        url: el,
      })),
    [intl, tabPaths],
  );

  const currentTab = location.pathname.split('/').pop();
  const currentTabIndex = tabPaths.indexOf(currentTab);
  const value = String(currentTabIndex !== -1 ? currentTabIndex : 0);

  const handleChange = (_, newValue) => {
    history.push(
      `/nodes/${contract}/users/${user_id}/${tabPaths[Number(newValue)]}`,
    );
  };

  if (!tabPaths.includes(currentTab))
    return (
      <Redirect
        to={`/nodes/${contract}/users/${user_id}/${ParticipantUrl.DETAILS}`}
      />
    );

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '30vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      <TitleContainer>
        <Typography variant='h1' component='h1' className='underline-left'>
          {data?.firstname} {data?.lastname}
        </Typography>
      </TitleContainer>
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '1.5rem',
          marginTop: '2rem',
        }}
      >
        <Box style={{ display: 'flex', padding: '1rem', gap: '1rem' }}>
          {isSuccess && (
            <>
              <ProfileCard user={data} />
              <StatusCard user={data} />
            </>
          )}
        </Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs onChange={handleChange} value={value}>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  value={tab.index.toString()}
                  disableFocusRipple
                />
              ))}
            </Tabs>
          </Box>
          <Switch>
            <Route
              path={`/nodes/${contract}/users/${user_id}/${ParticipantUrl.DETAILS}`}
              render={() => (
                <TabPanel value='0'>
                  {isSuccess && <DetailsTab user={data} />}
                </TabPanel>
              )}
            />
            <Route
              path={`/nodes/${contract}/users/${user_id}/${ParticipantUrl.POINTS}`}
              render={() => (
                <TabPanel value='1'>
                  <PointsList
                    queryParams={{
                      id: contract,
                      owner_types: [OwnerEnum.HIERARCHY_NODE_USER],
                      hierarchy_node_user: user_id,
                    }}
                  />
                </TabPanel>
              )}
            />
            <Route
              path={`/nodes/${contract}/users/${user_id}/${ParticipantUrl.ORDERS}`}
              render={() => (
                <TabPanel value='2'>
                  <OrdersList
                    queryParams={{
                      id: contract,
                      owner_types: [OwnerEnum.HIERARCHY_NODE_USER],
                      hierarchy_node_user: user_id,
                    }}
                  />
                </TabPanel>
              )}
            />
          </Switch>
        </TabContext>
      </Box>
    </Box>
  );
};

export default Users;
