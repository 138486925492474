import instance from '../instance';
import PaginationHelper from './helper/paginationHelper';

const baseUrl = 'system-files/';

const systemImages = {
  list() {
    return instance.get(baseUrl);
  },
  hierarchyNodeList(teamGroup = 1, codes = [], statuses = [], pagination) {
    const params = new URLSearchParams();
    if (codes && codes.length > 0) {
      codes.forEach(code => {
        params.append('codes', code);
      });
    }
    if (statuses && statuses.length > 0) {
      statuses.forEach(status => {
        params.append('status', status);
      });
    }

    return instance.get(`hierarchy-nodes/${teamGroup}/${baseUrl}`, {
      params: PaginationHelper(pagination, params),
    });
  },
  update(code, image) {
    const url = `${baseUrl}${code}/`;
    return instance.put(url, image);
  },
  hierarchyNodeUpdate(code, image, teamGroup = 1) {
    const url = `hierarchy-nodes/${teamGroup}/${baseUrl}`;

    return instance.post(url, image, {
      params: {
        code,
      },
    });
  },
  delete(imageId) {
    const url = `${baseUrl}${imageId}`;
    return instance.delete(url);
  },
};

export default systemImages;
