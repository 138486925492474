import { configureStore } from '@reduxjs/toolkit';
import systemFilesReducer from '../../features/systemFiles/getSystemFiles/slices';
import deleteSystemFilesReducer from '../../features/systemFiles/deleteSystemFiles/slices';
import updateSystemFilesReducer from '../../features/systemFiles/updateSystemFiles/slices';
import createSagaMiddleWare from 'redux-saga';
import Sagas from './sagas';

const createRegulationStore = () => {
  const sagaSystemFile = createSagaMiddleWare();

  const store = configureStore({
    reducer: {
      systemFiles: systemFilesReducer.getSystemFilesKey,
      deleteSystemFiles: deleteSystemFilesReducer.deleteSystemFilesKey,
      updateSystemFiles: updateSystemFilesReducer.updateSystemFilesKey,
    },
    middleware: () => [sagaSystemFile],
  });

  sagaSystemFile.run(Sagas);

  return store;
};

export default createRegulationStore;
